const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const { View } = require('@training/widgets/UIKit');
const KnowledgeItemView = require('./KnowledgeItemView');
const KnowledgeItemList = require('../collections/KnowledgeItemList');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const TrainingModulePreviewView = require('@training/apps/topics/TrainingModulePreviewView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const { allowDirectPreview } = require('@training/apps/topics/TopicPreviewHelper');

class KnowledgeItemListView extends View {
  events() {
    return {
      'click .extratrainingbuttons .viewtraining': 'viewTraining',
      'click .preview-module .preview-training': 'previewTraining'
    };
  }

  collectionEvents() {
    return {
      sync: 'render'
    };
  }

  constructor(options = {}) {
    super(options);

    this.renderItem = this.renderItem.bind(this);
    this.viewTraining = this.viewTraining.bind(this);

    ({
      collection: this.collection = new KnowledgeItemList()
    } = options);
  }

  render() {
    this.startClicked = false;
    this.$('.knowledgeitemwrapper').remove();

    if (this.collection.length === 0) {
      this.$('.notopics').removeClass('hidden');
    } else {
      this.$('.notopics').addClass('hidden');
      this.collection.each((item) => {
        this.renderItem(item);
      });
      this.trigger('force:icon:reflow');
    }

    this.triggerAdjustment();
    return this;
  }

  renderItem(item) {
    const itemView = new KnowledgeItemView({
      model: item,
      isExtraTrainingEnabled: this.getOption('isExtraTrainingEnabled')
    });
    this.$el.append(itemView.render().$el);
  }

  viewTraining(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.startClicked) {
      return false;
    }

    this.startClicked = true;

    const $knowledgeitem = $(e.currentTarget).closest('.knowledgeitem');
    const topicId = $knowledgeitem.data('topic-id');
    const level = $knowledgeitem.data('level');
    this.options.takeAction(topicId, level);

    return true;
  }

  previewTraining(e) {
    e.stopPropagation();
    e.preventDefault();
    const $knowledgeitem = $(e.currentTarget).closest('.knowledgeitem');
    const topicId = $knowledgeitem.data('topic-id');
    const topicLevel = $knowledgeitem.data('level');
    this.model = this.collection.get(`${ topicId }-${ topicLevel }`)

    if (allowDirectPreview(this.model.get('trainingModule').moduleType)) {
      this._confirmPreview()
    } else {
      const modalView = new AccessibleModalView({
        id: 'modalview',
        className: 'modal confirm-dialog-view modal--s'
      });

      const modalChildView = new ConfirmDialogView({
        confirmCallback: this._confirmPreview,
        model: this.model,
        title: I18n.t('trainingPreview.videoConfirmationModal.title'),
        confirmationText: I18n.t('trainingPreview.videoConfirmationModal.description'),
        buttonText: I18n.t('general.continue'),
        buttonColorClassName: 'default'
      });

      window.app.layout.presentModal(modalView, { closeClick: true });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenToOnce(modalChildView, 'destroy', () => {
        window.app.layout.dismissModal();
      });
    }
  }

  _confirmPreview() {
    window.app.layout.dismissModal();

    const modalView = new AccessibleModalView({
      id: 'modalView',
      className: 'modal training-preview-modal modal--s'
    })

    const modalChildView = new TrainingModulePreviewView({ trainingModule: this.model.get('trainingModule') });

    window.app.layout.presentModal(modalView, { closeClick: true });
    modalView.setSubviewIn(modalChildView);

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }
}

module.exports = KnowledgeItemListView;
