const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const TopicStatus = require('@common/data/enums/TopicStatus');
const KnowledgeItemWarningLevel = require('@training/apps/training/enums/KnowledgeItemWarningLevel');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const { getPreviewButtonText } = require('@training/apps/topics/TopicPreviewHelper');

class KnowledgeItemView extends Marionette.LayoutView {

  tagName() {
    return 'li';
  }

  className() {
    return 'knowledgeitemwrapper clearfix';
  }

  getTemplate() {
    return require('../templates/_knowledge_item.html');
  }


  templateHelpers() {
    const item = this.model.toJSON();
    const { isExtraTrainingEnabled } = this.options;
    const alreadyPerformed = window.app.sessionController.session.hasExtraQuestionsAssessmentsForTopicIdLevel(item.topic.id, item.level);
    const {
      percentageHighlightClass,
      percentageHighlightA11yNote
    } = this.getPercentageHighlightInfo(item);

    const hasTrainingModule = item.trainingModule != null;
    const modulePreviewButtonLabel = hasTrainingModule ? getPreviewButtonText(item.trainingModule.moduleType) : '';
    const trainingModulePreviewEnabled = TenantPropertyProvider.get().getProperty('trainingModulePreviewEnabled');

    return {
      item,
      title: I18n.t('knowledge.questionsTitle', {
        part: item.correctAnswerCount,
        total: item.totalAnswerCount
      }),
      percentageHighlightClass,
      percentageHighlightA11yNote,
      percentageString: this.getPercentageString(item),
      performanceClass: this.getPerformanceClass(),
      performanceTitle: this.getPerformanceTitle(),
      isOnlyTraining: item.onlyTraining,
      isExtraTrainingEnabled: isExtraTrainingEnabled && (item.trainingAvailable || item.questionsAvailable) && !alreadyPerformed,
      takeTrainingButtonClass: this.model.isRecommended() ? '' : 'white',
      isRecommended: this.model.isRecommended(),
      hasInProgressAssessment: item.inProgressAssessmentGetter() != null,
      hasTrainingModule: hasTrainingModule,
      trainingModulePreviewEnabled: trainingModulePreviewEnabled,
      modulePreviewButtonLabel: modulePreviewButtonLabel
    };
  }

  getPercentageHighlightInfo(item) {
    let percentageHighlightClass,
      percentageHighlightA11yNote = '';

    if (item.warningLevel === KnowledgeItemWarningLevel.High) {
      percentageHighlightClass = 'score-high-notice';
      percentageHighlightA11yNote = I18n.t('knowledge.scoreHighNotice');
    } else if (item.warningLevel === KnowledgeItemWarningLevel.Low) {
      percentageHighlightClass = 'score-low-warning';
      percentageHighlightA11yNote = I18n.t('knowledge.scoreLowWarning');
    }

    return {
      percentageHighlightClass,
      percentageHighlightA11yNote
    };
  }

  getPercentageString(item) {
    if (item.onlyTraining) {
      return I18n.t('general.notApplicable');
    }
    return `${ Math.floor(item.currentMetrics.percentCorrect) }%`;

  }

  getPerformanceClass() {
    switch (this.model.get('topicStatus')) {
      case TopicStatus.NEEDS_COACHING: return 'needs-coaching';
      case TopicStatus.OVERCONFIDENT: return 'overconfident';
      case TopicStatus.UNDERCONFIDENT: return 'underconfident';
      case TopicStatus.EXPERT: return 'expert';
      case TopicStatus.GRADUATE: return 'graduated';
      case TopicStatus.INTERMEDIATE: return 'intermediate';
      case TopicStatus.BEGINNER: return 'beginner';
      case TopicStatus.NOT_STARTED: return 'not-started';
      default: return '';
    }
  }

  getPerformanceTitle() {
    const topicName = (() => {
      switch (this.model.get('topicStatus')) {
        case TopicStatus.NEEDS_COACHING: return 'needsCoaching';
        case TopicStatus.OVERCONFIDENT: return 'overconfident';
        case TopicStatus.UNDERCONFIDENT: return 'underconfident';
        case TopicStatus.EXPERT: return 'expert';
        case TopicStatus.GRADUATE: return 'graduated';
        case TopicStatus.INTERMEDIATE: return 'intermediate';
        case TopicStatus.BEGINNER: return 'beginner';
        case TopicStatus.NOT_STARTED: return 'notStarted';
        default: return '';
      }
    })();

    return I18n.t(`knowledge.statusTitle.${ topicName }`);
  }
}

module.exports = KnowledgeItemView;
