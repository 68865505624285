const Backbone = require('Backbone');

class ModuleLinksCollection extends Backbone.Collection {
  getPackageForLanguage(language) {
    return this.find((pkg) => {
      return pkg.get('language') === language;
    });
  }
}

module.exports = ModuleLinksCollection;
