const { extend } = require('underscore');
const logging = require('logging');
const $os = require('detectOS');
const env = require('env');
const $ = require('jquery');
const ErrorHandler = require('ErrorHandler');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const { REMOTE_LOG_LEVELS } = require('LoggingService');
const AjaxHelpers = require('@common/libs/ajax/AjaxHelpers');
const AuthenticatedUrlEndpoint = require('@common/libs/ajax/AuthenticatedUrlEndpoint');
const ObjectHelpers = require('@common/libs/helpers/types/ObjectHelpers');
const { isBoolean } = require('lodash');

const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const IFrameView = require('@common/components/iframeView/IFrameView');
const TimeLogController = require('@common/components/time_logs/TimeLogController');

const ExternalLinkHandlerType = require('@common/data/enums/ExternalLinkHandlerType');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');
const { combineDuplicateTimelogs } = require('@common/components/time_logs/TimeLogProcessor');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

class App {
  constructor() {
    this.version = env.settings.version;

    this.onStartEvent = this.onStartEvent.bind(this);
    this.onEndEvent = this.onEndEvent.bind(this);
    this.openUrl = this.openUrl.bind(this);
    this.globalErrorHandler = this.globalErrorHandler.bind(this);
    this.getTeamlinkHref = this.getTeamlinkHref.bind(this);
    this.getKnowledgeHref = this.getKnowledgeHref.bind(this);
    this.checkDoubleSubmit = BrowserHelpers.checkDoubleSubmit.bind(this);

    const postProcessingFn = (timelogs) => {
      return combineDuplicateTimelogs(timelogs, [
        TimeLogConfig.PageView.Discovery.type,
        TimeLogConfig.PageView.DiscoveryArticlesTab.type,
        TimeLogConfig.PageView.DiscoveryTopicsTab.type,
        TimeLogConfig.PageView.DiscoveryPathsTab.type,
        TimeLogConfig.PageView.DiscoveryCommunity.type
      ]);
    };

    this.timeLogController = new TimeLogController({
      TimeLogConfig,
      appIdentifier: env.settings.app,
      postProcessingFn
    });

    this.tenantStore = TenantPropertyProvider.get();

    this.imageFolder = `/training/${ this.version }/images`;

    this.initializeAuthUrlExtension();

    // Cancel the default actions for links with href '#'
    $(document).on('click', 'a[href=\'#\']', this.cancelAction);

    // Handle external URLs (i.e. target="_blank" in our platform)
    $(document).on('click', 'a[target=\'_blank\']', this.openUrl);

    // Set a global error handler
    $(document).ajaxError(this.globalErrorHandler);

    // Track touch events to support button pressed state.
    this.touchStartPosition = null;
    this.t = null;
    $(document).on('touchstart', 'a, .touchable', this.onStartEvent);

    $(document).on('touchmove', 'a, .touchable', (e) => {
      const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

      // Cancel touch tracking when scrolling is detected
      const touchX = Math.abs(touch.clientX - this.touchStartPosition.x);
      const touchY = Math.abs(touch.clientY - this.touchStartPosition.y);
      if (this.touchStartPosition && ((touchX > 10) || (touchY > 10))) {
        if (this.startTarget != null) {
          this.startTarget.removeClass('pressed');
        }
        // clearTimeout(@t)
        // @cancelAction(e)
        return true;
      }
      return undefined;
    });

    $(document).on('touchend', 'a, .touchable', this.onEndEvent);

    // To prevent draging of clickable elements
    BrowserHelpers.preventDragAndDrop();

    // Enable button pressed state on mousedown
    $(document).on('mousedown', 'a, .touchable', this.onStartEvent);
    $(document).on('mouseup', 'a, .touchable', this.onEndEvent);
    $(document).on('mouseleave', 'a, .touchable', this.onEndEvent);
    $(document).on('click', 'a, .touchable', (e) => {
      return $(e.currentTarget).removeClass('pressed');
    });

    if ($os.mobile) {
      BrowserHelpers.hideAddressBar();
    }

    // NativeBridge
    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
  }

  onStartEvent(e) {
    if ($os.mobile || (!$(e.currentTarget).prop('tagName')
      .toLowerCase() === 'a')) {
      $(e.currentTarget).addClass('pressed');
    }

    this.startTarget = $(e.currentTarget);
    if (e.type.indexOf('touch') === 0) {
      const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
      this.touchStartPosition = {
        x: touch.clientX,
        y: touch.clientY
      };
    }
  }
  // @t = setTimeout ->
  //   $(e.currentTarget).addClass 'pressed'
  // , 20
  // @cancelAction(e)
  // else

  onEndEvent() {
    // $(e.currentTarget).removeClass 'pressed'

    if (this.startTarget != null) {
      this.startTarget.removeClass('pressed');
    }
    this.startTarget = null;
  }
  // if e.type.indexOf('touch') is 0
  // clearTimeout(@t)
  // @touchStartPosition = null
  // $(e.currentTarget).removeClass 'pressed'
  // @cancelAction(e)

  // generate click event here to get the click event concurrently to the touchend event to prevent
  // touch
  // clickGen = document.createEvent 'MouseEvent'
  // touch = e.originalEvent.changedTouches[0]
  // clickGen.initMouseEvent(
  //   'click', true, true, e.originalEvent.target.ownerDocument.defaultView, 0,
  //   touch.screenX, touch.screenY, touch.clientX, touch.clientY,
  //   e.ctrlKey, e.altKey, e.shirtKey, e.metaKey, 0, null)
  // e.originalEvent.target.dispatchEvent clickGen
  // else
  //   $(e.currentTarget).removeClass 'pressed'

  // Cancel default event handling
  cancelAction(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  /**
   * @see: COMM-152
   * @returns boolean
   *
   * Fact: mobileExternalLinkHandler intercepts links in the mobile app, and forces them to open a new window
   * instead of a child view (a "child view" is a new tab, opened using "_blank")
   *
   * Rogers has tight security on people opening links, but they *do* want people to be able to follow links
   * coming from Axonify. So they allow-listed Axonify as a trusted referrer... which is great... provided the
   * HTTP request has a REFERER.
   *
   * Here is the bad news. External links opened in a new window using mobileExternalLinkHandler have no REFERER.
   * So these new windows with no referrer were being blocked!
   *
   * In situations where a customer has tight security around referers (like Rogers), we need to let the mobile app
   * open a child view (using _blank), not a new window. In other words, for Rogers, on mobile, we do not want links
   * to be handled by this.mobileExternalLinkHandler.
   *
   * There are two criteria:
   * - the tenant property "contentLinkOpensNewWindow" must be on, so we can limit this odd behaviour to just
   *   certain tenants (notably, Rogers).
   * - this is only relevant for links opening in the mobile app.
   */
  forceToBeOpenedInChildView() {
    const isInMobileApp = $os.isInMobileApp();
    const forceToOpenInChildWindow = this.tenantStore.getProperty('contentLinkOpensNewWindow');
    return isInMobileApp && forceToOpenInChildWindow;
  }

  /**
   * click handler for <a> links
   * @param {*} e
   * @returns boolean, but may have side effects
   * All <a> links with the target "_blank" are forced to call this method onClick.
   * If this method returns true, then the click just happens old-school and the browser
   * opens a new tab. Returning false cancels the click event entirely.
   *
   * And then there's the case where this is a mobile app, and we don't want external links
   * opening in a tab, because that screws up the mobile view. In the mobile experience we
   * will (usually) make external links open in a new window.
   */
  openUrl(e) {
    if (this.checkDoubleSubmit()) {
      return false;
    }

    const $curTarget = $(e.currentTarget);

    // check anchor's href value
    const href = $curTarget.prop('href'); // http://stackoverflow.com/q/2639070

    let linkText = $curTarget.text();
    if (linkText.length === 0) {
      linkText = href;
    }

    let externalLinkHandler;
    try {
      externalLinkHandler = ExternalLinkHandlerType.assertLegalValue(this.tenantStore.getProperty('openExternalLinks'));
    } catch (error) {
      externalLinkHandler = ExternalLinkHandlerType.getDefaultValue();
    }

    // for tenants with tight security around referrers, we might force mobile links to open in a _blank instead of
    // the usual way which is to open a new window.
    if (this.forceToBeOpenedInChildView()) {
      return true;
    }

    switch (externalLinkHandler) {
      case ExternalLinkHandlerType.DISABLED:
        window.app.layout.flash.error(I18n.t('flash.externalLinkNotSupported'));
        return this.cancelAction(e);
      case ExternalLinkHandlerType.IFRAME:
        this.renderIFrame(href, linkText);
        return this.cancelAction(e);
      case ExternalLinkHandlerType.NEW_WINDOW:
        if ($os.isInMobileApp()) {
          this.mobileExternalLinkHandler(href, linkText);
          return this.cancelAction(e);
        }
        // In the case where `NEW_WINDOW` and not mobile then don't `cancelAction`, let the `target="_blank"` proceed.
        return true;
      default:
        return true;
    }
  }

  mobileExternalLinkHandler(href, linkText) {
    // if wrapper supports it, make it open the url instead of
    // rendering the iframe
    const canOpenUrl = this.nbChannel.reqres.request('canOpenUrl');

    if (canOpenUrl && UrlHelpers.isExternalLink(href)) {
      this.nbChannel.vent.once('urlClosed', (data) => {
        // if there is an errorCode, flash the message
        const {errorCode} = data || {};
        if (errorCode != null) {
          window.app.layout.flash.error(I18n.t(`errors.nativeWrapper.${ errorCode }`));
        }
      });

      this.nbChannel.commands.execute('openUrl', {
        url: href,
        title: linkText
      });
    } else {
      this.renderIFrame(href, linkText);
    }
  }

  renderIFrame(href, linkText) {
    const text = linkText || href;

    // get instance of iFrame view and render it
    const iFrameView = new IFrameView({
      href,
      linkText: text,
      el: $('body')
    });
    iFrameView.render();
  }

  globalErrorHandler(event, xhr, settings = {}, thrownError) {
    // TODO - Refactor the skipGlobalHandler flag to be an object that specifies skip
    // conditions/function instead of just a true/false value. See SE-1845
    if (ObjectHelpers.getValue(settings.skipGlobalHandler, this, xhr, settings, thrownError) || ObjectHelpers.getValue(xhr.skipGlobalHandler, this, xhr, settings, thrownError) ) {
      return;
    }

    let showErrorPage;
    if (isBoolean(xhr.shouldShowErrorPage)) {
      showErrorPage = xhr.shouldShowErrorPage;
    }
    if (isBoolean(settings.shouldShowErrorPage)) {
      showErrorPage = settings.shouldShowErrorPage;
    }

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    const errorMessageObject = {
      errorDetailsMessage: I18n.t('errors.genericError'),
      api: AjaxHelpers.getApiErrorData({
        xhr,
        settings,
        exception,
        thrownError
      }),
      shouldShowErrorPage: showErrorPage
    };

    switch (xhr.status) {
      case 0: {
        let thrownErrorKey;
        if (thrownError === 'timeout') {
          logging.error('The network call timed out.');
          thrownErrorKey = 'timeout';
        } else { // No Internet connection
          Object.assign(errorMessageObject, {
            level: REMOTE_LOG_LEVELS.WARN
          });
          logging.error('No Internet connection');
          thrownErrorKey = 'noConnection';
        }

        Object.assign(errorMessageObject, {
          errorDetailsMessage: I18n.t(`errors.${ thrownErrorKey }`),
          buttonText: I18n.t('general.continue')
        });
        break;
      }
      case 400:
        if ([
          AxonifyExceptionCode.CLIENT_ERROR_CANT_PERFORM_OPERATION,
          AxonifyExceptionCode.CLIENT_ERROR_GAME_CONFIGURATION,
          AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY,
          AxonifyExceptionCode.CLIENT_ERROR_INVALID_INPUT,
          AxonifyExceptionCode.CLIENT_ERROR_STALE_ENTITY
        ].includes(errCode)) {
          logging.error(`400 Error. Error code ${ errCode }.`);

          Object.assign(errorMessageObject, {
            errorDetailsMessage: I18n.t('errors.400', {errCode})
          });

          window.app.layout.flash.error(I18n.t('flash.serviceDown'));

        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_UNSAFE_CONTENT) {
        // the form data contains a possible xss attack so let the user know
        // that there is something that is malformed
          logging.warn('XSS issue');
          window.app.layout.flash.error(I18n.t('flash.common.3064'));
          return;
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_SESSION_STATE) {
        // This is a session state error, this means that the user
        // has multiple sessions started and it should therefore
        // not send a log to the server but should let the user
        // know that they have done something elsewhere and the browser
        // needs to be reloaded
          Object.assign(errorMessageObject, {
            errorDetailsMessage: I18n.t('errors.400', {errCode}),
            buttonText: I18n.t('general.continue'),
            showDetails: false
          });

        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY) {
          window.app.layout.flash.error(I18n.t('flash.common.3008'));
          return;
        } else {
          logging.error(`400 Error. Unhandled Error code: ${ errCode }.`);
        }
        break;

      case 401:
      case 302: {
        logging.error('Not authenticated. You need to sign in.');

        window.app.layout.flash.error(I18n.t('flash.sessionExpired'));

        // The server can return a redirectURL with an error code (primarily used for SAML)
        const redirectURL = exception.getResponse().redirectURL;
        if ((errCode === AxonifyExceptionCode.SERVER_ERROR_REDIRECT) && redirectURL) {
          logging.info(`Received error code '${ errCode }', redirecting to '${ redirectURL }'`);
          window.location.replace(redirectURL);
        } else {
          logging.info(`Received error code '${ errCode }'`);
        }

        RedirectingAbortedAuthentication.catchAndLog(() => {
          window.apps.auth.redirectToLoginPage({redirectTo: window.location.href});
        });

        return;
      }
      case 403:
        if (errCode === AxonifyExceptionCode.SERVER_ERROR_IP_FORBIDDEN) {
          logging.error('403 Error. IP Whitelist');

          Object.assign(errorMessageObject, {
            errorMessage: I18n.t('logErrors.9021'),
            errorDetailsMessage: I18n.t('errors.auth.9021'),
            buttonText: I18n.t('general.continue'),
            showDetails: false,
            sendLog: false
          });
        }
        break;

      case 500:
      case 503:
        window.app.layout.flash.error(I18n.t('flash.serviceDown'));

        Object.assign(errorMessageObject, {
          errorDetailsMessage: I18n.t('errors.serverError')
        });
        break;
      default:
        /* nomnomnom */
    }

    this.redirectToErrorPage(errorMessageObject);
  }

  redirectToErrorPage(options = {}) {
    return ErrorHandler.handleError(options);
  }

  getTeamlinkHref() {
    const teamlinkLaunchableFromHub = this.tenantStore.getProperty('teamlinkLaunchableFromHub');
    const hasUserTypeAccess = window.apps.auth.session.user.hasManagerAppAccess();

    if (hasUserTypeAccess && teamlinkLaunchableFromHub) {
      return '/teamlink';
    }
    return null;

  }

  getAdminLinkOptions() {
    const hasContent = window.apps.auth.session.get('shouldShowContent');
    const isMobile = $os.mobile || $os.tablet || $os.isInMobileApp();
    const hasUserTypeAccess = window.apps.auth.session.user.hasAdminAppAccess();

    if (!isMobile && (hasUserTypeAccess || hasContent)) {
      return {
        url: '/admin',
        text: I18n.t('menu.apps.admin')
      };
    }

    return null;
  }

  getKnowledgeHref(hasSomeDiscoveryZoneCommunityAvailable) {
    const discoveryZoneEnabled = this.tenantStore.getProperty('discoveryZoneEnabled') || false;
    const isAdminUser = window.apps.auth.session.user.isAdminUser();
    const hasUserTypeAccess = window.apps.auth.session.user.hasKnowledgeAppAccess();

    if ( discoveryZoneEnabled && ( hasSomeDiscoveryZoneCommunityAvailable || isAdminUser ) && hasUserTypeAccess) {
      return this.getKnowledgeHrefRaw();
    }
    return null;

  }

  getKnowledgeHrefRaw() {
    return '/training/index.html#hub/search/articles/1/';
  }

  initializeAuthUrlExtension() {
    const authFragment = () => {
      return window.apps.auth.getAuthFragment();
    };

    $.ajaxPrefilter(AuthenticatedUrlEndpoint.createApiEndpointPrefilter({ authFragment }));

    const urlBuilder = new AuthenticatedUrlEndpoint.UrlBuilder({ authFragment });

    // Extend Model prototype to build our custom endpoint urls
    extend(Backbone.Model.prototype, urlBuilder, { urlRoot() {
      return this.apiEndpointUrl();
    } });

    // Extend Collection prototype to build our custom endpoint urls
    extend(Backbone.Collection.prototype, urlBuilder, { url() {
      return this.apiEndpointUrl();
    } });
  }
}

module.exports = App;
